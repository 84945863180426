import * as React from 'react';
import { Card, CardBody, Row, Col, Form, Popover, PopoverBody } from 'reactstrap';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { RootState } from '@src/app/_redux';
import { actionCreators } from '@src/app/login/weak/nameaddress/actions';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import { TextInput, SimpleSelect, Checkbox } from '@src/app/_global/components/form-elements';
import { usStates, countries, countriesWithStatesOrProvinces, countriesWithNoPostalCodes, countriesWithProvinces } from '@src/app/_global/constants';
import { required, email } from '@src/app/_global/field-validations';
import '../login.scss';
import ReCAPTCHA from 'react-google-recaptcha';
import { getProdIdsForGlobalCodeFromAdminConfig } from '@src/app/accounts/selectors';
import { bindActionCreators, Dispatch } from 'redux';

interface Props {
  handleSubmit: any;
  onSubmitHandler: any;
  intl: any;
  signInData: any;
  formCountry: any;
  removeField: any;
}

interface State {
  disableSubmitBtn: boolean;
  emailOptInChecked: boolean;
  popoverOpenEmailHelp: boolean;
}
function createMarkup(nodeItem: any) {
  return {__html: nodeItem };
}
const PopoverElEmail = ({ toggle, popoverOpen }) => {
  return (
    <>
      <span className="email-help">
        <button
          type={'button'}
          onClick={toggle}
          id={'Popover-EmailHelp'}
          className="pointer ml-2 icon-blue fa fa-question-circle fa-1x emailhelp-button"
          aria-label="show email help"
       />
      </span>
      <Popover
        placement="top"
        isOpen={popoverOpen}
        target={'Popover-EmailHelp'}
        toggle={toggle}
        className="email-help"
        aria-label="email help"
      >
        <PopoverBody>
          <div>
            <span>Enter your email address and check the box below to update your default email address.</span>
          </div>
        </PopoverBody>
      </Popover>
    </>
  );
};
const validate = values => {
  const errors: any = {};
  if (values.country === 'US') {
    if (!values.state) {
      errors.state = 'Required';
    }
    if (!values.postalCode) {
      errors.postalCode = 'Required';
    }
    if (isNaN(Number(values.postalCode))) {
      errors.postalCode = 'Must be a number';
    }
    if (values.postalCode && values.postalCode.length !== 5) {
      errors.postalCode = 'Must be 5 characters';
    }
  } else if (values.country === 'CA') {
    if (!values.state) {
      errors.state = 'Required';
    }
    if (!values.postalCode) {
      errors.postalCode = 'Required';
    }
    if (values.postalCode && values.postalCode.length !== 6) {
      errors.postalCode = 'Must be 6 characters';
    }
    if (!/[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d/.test(values.postalCode)) {
      errors.postalCode = 'Invalid Postal Code';
    }
  } else {
    if (values.country in countriesWithStatesOrProvinces && !values.state) {
      errors.state = 'Required';
    }
    const country = countriesWithNoPostalCodes.find(item => item.value === values.country);
    if (values.country && !country && !values.postalCode) {
      errors.postalCode = 'Required';
    }
  }
  return errors;
};

export class NameAddressLoginForm extends React.Component<Props> {

  state: State = {
    disableSubmitBtn: true,
    emailOptInChecked: true,
    popoverOpenEmailHelp: false,
  };

  constructor(props: Props) {
    super(props);
    this.handleCountryChange = this.handleCountryChange.bind(this);
  }
  toggleEmailHelp() {
    this.setState({
      popoverOpenEmailHelp: !this.state.popoverOpenEmailHelp,
    });
  }
  recaptchaSuccess = (value: string) => {
    // If no value, the reCaptcha has timed out.  Disable the submit button until user rechecks reCaptcha.
    this.setState({
      disableSubmitBtn: value ? false : true,
    });
  }
  handleOptInClick = (evt: React.SyntheticEvent<HTMLInputElement>) => {
    const isChecked = evt.currentTarget.checked;
    this.setState({ 
      emailOptInChecked: isChecked === true,
    });
  }
  handleCountryChange = event => {
    if (!(event.value in countriesWithStatesOrProvinces))  {
      this.props.removeField('login-form-addr', 'state', '');
    }
    return true;
  }
  formatLabels = (messId, messDisc, messDefault) => {
    let labelText = this.props.intl.formatMessage({
     id: messId,
     description: messDisc,
     defaultMessage: messDefault,
   });
   return labelText;
  }
  
public render() {
    const { handleSubmit, onSubmitHandler, signInData, formCountry } = this.props;

    const fName = this.formatLabels('app.firstname', 'First name field', 'First Name *');
    const lName = this.formatLabels('app.lastname', 'Last name field', 'Last Name *');
    const address1 = this.formatLabels('app.address1', 'Address 1 field', 'Address 1 *');
    const address2 = this.formatLabels(
      'app.address2',
      'Address 2 field',
      'Address 2',
    );
    const lcity = this.formatLabels('app.city', 'City field', 'City *');
    const lstate = this.formatLabels('app.state', 'State field', 'State *');
    const lprovince = this.formatLabels('app.prov', 'Province field', 'Province *');
    const lstateprovince = this.formatLabels('app.stprov', 'State/Province field', 'State/Province *');
    const lzip = this.formatLabels('app.zip', 'zip postal label', 'ZIP/Postal Code *');
    const lcountry = this.formatLabels('app.country', 'Country label', 'Country *');
    const OptLabel = <span dangerouslySetInnerHTML={createMarkup(signInData.EmailOptInText )} />;
  
    let disableSubmitBtn = false;
    if (signInData.RecaptchaEnabled) {
      // recaptcha enabled.  Let local state determine if we show the button or not.
      disableSubmitBtn = this.state.disableSubmitBtn;
    }
    let stateOptions = [{}];
    if (formCountry in countriesWithStatesOrProvinces) {
      stateOptions = countriesWithStatesOrProvinces[formCountry];
    } else {
      stateOptions = usStates;
    }

    let stateLabel = (formCountry === undefined ? lstateprovince : ((formCountry in countriesWithProvinces) ? lprovince : lstate));
    let displayStateProvince = (formCountry in countriesWithStatesOrProvinces || formCountry === undefined);
    return (
      <React.Fragment>
      {signInData.NameAddressEnabled  && (
      <Form
        inline={true}
        id="login-form"
        onSubmit={handleSubmit(onSubmitHandler)}
      >
        <Card>
          <CardBody>
            <Row>
              <Col xs={12} sm={12} md={6}>
                <Field
                  id="login-first-name"
                  label={fName}
                  name="firstName"
                  validate={[required]}
                  component={TextInput}
                  type="text"
                />
              </Col>
              <Col xs={12} sm={12} md={6}>
                <Field
                  id="login-last-name"
                  label={lName}
                  name="lastName"
                  validate={[required]}
                  component={TextInput}
                  type="text"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={6}>
                <Field
                  id="address1-field"
                  label={address1}
                  name="address1"
                  validate={[required]}
                  component={TextInput}
                  type="text"
                />
              </Col>
              <Col xs={12} sm={12} md={6}>
                <Field
                  id="address2-field"
                  label={address2}
                  name="address2"
                  component={TextInput}
                  type="text"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={6}>
                <Field
                  id="city-field"
                  label={lcity}
                  name="city"
                  validate={[required]}
                  component={TextInput}
                  type="text"
                />
              </Col>
              <Col className="mb-2" xs={12} sm={12} md={6}>
                <Field
                  id="country-field"
                  label={lcountry}
                  name="country"
                  validate={[required]}
                  component={SimpleSelect}
                  options={countries}
                  onChange={this.handleCountryChange}
                />
              </Col>
            </Row>
            <Row>
              {displayStateProvince && (
                <Col xs={12} sm={12} md={6}>
                  <Field
                  id="state-field"
                  label={stateLabel}
                  name="state"
                  component={SimpleSelect}
                  options={stateOptions}
                />
              </Col>
              )}
              <Col xs={12} sm={12} md={6}>
                <Field
                  id="zip-field"
                  label={lzip}
                  name="postalCode"
                  component={TextInput}
                  type="text"
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
        {signInData.CaptureEmail && (
        <Card>
          <CardBody>
          <Row>
            <Col  xs={12} sm={12} md={12} lg={6} className="weak-login-input-field">
              <Field
                name="captureEmail"
                id={'captureEmail'}
                className="capture-email"
                type="text"
                label={'Email '}
                validate={[email]}
                component={TextInput}
              />
              {signInData.EmailCaptureHelp && (
                <PopoverElEmail
                  toggle={() => this.toggleEmailHelp()}
                  popoverOpen={this.state.popoverOpenEmailHelp}
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} className="checkbox-col">
              <Field
                name="emailOptIn"
                id={'emailOptIn'}
                className="checkbox-label"
                component={Checkbox}
                checked={this.state.emailOptInChecked}
                onChange={this.handleOptInClick}
                label={OptLabel}
              />
            </Col>
          </Row>
          </CardBody>
        </Card>
        )}

        <Card>
          <CardBody>
            { signInData.RecaptchaEnabled &&
              (
                <Row>
                  <Col>
                    <ReCAPTCHA className="flexContainer mt-3 mb-2 recaptcha"
                      sitekey="6LcQ14YUAAAAAKsWbAnGKL3Dh7BNEyIbMKUeplOV"
                      onChange={this.recaptchaSuccess}
                    />
                  </Col>
                </Row>
             )
            }
            <Row>
              <Col>
                <button className="btn btn-primary" type="submit" disabled={disableSubmitBtn}>
                  <FormattedMessage
                    id="app.btnfindacnt"
                    description="find account button"
                    defaultMessage={'Find Your Account'}
                  />
                </button>
              </Col>
            </Row>
          </CardBody>
        </Card>
     </Form>
    )}
    </React.Fragment>
    );
  }
}
const nameAddrForm = reduxForm({
  form: 'login-form-addr',
  validate,
})(NameAddressLoginForm);
const selector = formValueSelector('login-form-addr');
const mapStateToProps = (state: RootState) => {
  return {
    initialValues: {
      'emailOptIn': true,
      'captureEmail': '',
      'productExcludes': getProdIdsForGlobalCodeFromAdminConfig(state),
      'state': '',
      'postalCode': '',
    },
    formCountry: selector(state, 'country'),
  };
};
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      onSubmitHandler: actionCreators.nameAddressLogin,
      removeField: (form, field, newValue) => dispatch(change(form, field, newValue)),
    },
    dispatch,
  );

export const NameAddressLogin = injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(nameAddrForm),
);
