import { combineReducers } from 'redux';
import { RootAction } from '@src/app/_redux';
import {
  GET_ENTITLED_ISSUES_SUCCESS,
  GET_ENTITLED_ISSUES_LOADING,
  GET_ENTITLED_ISSUES_ERROR,
  SUBSCRIPTION_EXTENSION_ERROR,
  SUBSCRIPTION_EXTENSION_LOADING,
  SUBSCRIPTION_EXTENSION_SUCCESS,
  SUBSCRIPTION_EXTENSION_CANCEL,
  REPLACEMENT_ISSUE_ERROR,
  REPLACEMENT_ISSUE_LOADING,
  REPLACEMENT_ISSUE_SUCCESS,
  MISSED_DAMAGED_CANCEL,
} from '@src/app/accounts/issues/actions';

export type State = {
  readonly entitledIssuesLoading: boolean;
  entitledIssues: Array<any> | null;
  readonly missedDamagedLoading: boolean;
  missedDamagedResult: any;
};

export const reducer = combineReducers<State, RootAction>({
  entitledIssuesLoading: (state = false, action) => {
    switch (action.type) {
      case GET_ENTITLED_ISSUES_SUCCESS:
      case GET_ENTITLED_ISSUES_ERROR:
        return false;
      case GET_ENTITLED_ISSUES_LOADING:
        return action.payload;
      default:
        return state;
    }
  },
  entitledIssues: (state = null, action) => {
    switch (action.type) {
      case GET_ENTITLED_ISSUES_SUCCESS:
        return action.payload.entitledIssues;
      case GET_ENTITLED_ISSUES_ERROR:
        return null;
      default:
        return state;
    }
  },
  missedDamagedLoading: (state = false, action) => {
    switch (action.type) {
      case SUBSCRIPTION_EXTENSION_SUCCESS:
      case SUBSCRIPTION_EXTENSION_ERROR:
      case REPLACEMENT_ISSUE_SUCCESS:
      case REPLACEMENT_ISSUE_ERROR:
        return false;
      case SUBSCRIPTION_EXTENSION_LOADING:
      case REPLACEMENT_ISSUE_LOADING:
        return action.payload;
      default:
        return state;
    }
  },
  missedDamagedResult: (state = false, action) => {
    switch (action.type) {
      case SUBSCRIPTION_EXTENSION_ERROR:
      case SUBSCRIPTION_EXTENSION_SUCCESS:
      case REPLACEMENT_ISSUE_SUCCESS:
      case REPLACEMENT_ISSUE_ERROR:
        return action.payload;
      case SUBSCRIPTION_EXTENSION_CANCEL:
      case MISSED_DAMAGED_CANCEL:
        return null;
      default:
        return state;
    }
  },
});
