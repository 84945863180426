import { combineEpics, Epic, ActionsObservable, StateObservable, ofType } from 'redux-observable';
import { RootAction, RootState } from '@src/app/_redux';
import { ApiClient } from '@src/app/_global/utility/apiUtils';
import { ApiConfig } from '@src/app/_global/domain';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import { concat } from 'rxjs/observable/concat';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { actionCreators, SERIES_UPDATE_START } from '@src/app/accounts/series-update/actions';
import { actionCreators as weakActionCreators } from '@src/app/login/weak/actions';
import { actionCreators as gaActionCreators } from '@src/app/analytics/googleAnalytics/actions';
import { getLoggedInCustomer } from '@src/app/accounts/selectors';
import {  SeriesUpdatePutbody } from '@src/_api/payload/series';
import { forkJoin } from 'rxjs/observable/forkJoin';
import { OrderUpdatePutbody } from '@src/_api/payload';

const SERIES_PATH = '/series';
const ORDERUPDATE_PATH = '/orderupdate';
const getSeriesUpdateSucceeded = (...responses) => {
  const successResponses = responses.filter(item => 
    item.success === true);
  return successResponses;
};
const updateSeries =  (
  action$: ActionsObservable<RootAction>,
  state$: StateObservable<RootState>,
) =>
    action$.pipe(
      ofType(SERIES_UPDATE_START),
      mergeMap(({ payload }) => {
      const client = new ApiClient();
      const clientConfig = state$.value.clientConfig.clientConfig;
      const loading = of(actionCreators.seriesUpdateLoading(true));

      let gaEventCategory;
      const promises = payload.map(seriesUpdate => {
        gaEventCategory = seriesUpdate.googleAnalyticsCategory;
        let putBody: SeriesUpdatePutbody | OrderUpdatePutbody ;
        let path;
        if (seriesUpdate.systemCode === 'CORE') {
          if (seriesUpdate.updateType === 'frequency') {
            putBody = {
              prodIdAlias: seriesUpdate.prodIdAlias,
              accountNumber: seriesUpdate.accountNumber,
              systemCode: seriesUpdate.systemCode,
              coreTransactionType: seriesUpdate.updateType,
              order: {
                entityId: seriesUpdate.entityId,
                entityType: seriesUpdate.entityType,
                shipFrequency: seriesUpdate.shipFrequency,
              }
            };
          } else {
            putBody = {
              prodIdAlias: seriesUpdate.prodIdAlias,
              accountNumber: seriesUpdate.accountNumber,
              systemCode: seriesUpdate.systemCode,
              coreTransactionType: 'discontinue',
              order: {
                entityId: seriesUpdate.entityId,
                entityType: seriesUpdate.entityType,
                reasonCode: seriesUpdate.reasonCode
              }
            };
          }
          path = ORDERUPDATE_PATH;
        } else {
          putBody = {
            prodIdAlias: seriesUpdate.prodIdAlias,
            accountNumber: seriesUpdate.accountNumber,
            recipAccountNumber: seriesUpdate.recipAccountNumber,
            updateType: seriesUpdate.updateType,
            suspendSwitch: seriesUpdate.suspendSwitch,
            planCode: seriesUpdate.planCode,
            planStatus: seriesUpdate.planStatus,
            shipFrequency: seriesUpdate.shipFrequency,
            shipQuantity: seriesUpdate.shipQuantity,
          };
          path = SERIES_PATH;
        }

        const apiConfig: ApiConfig = {
          credentialsObject: state$.value.auth.omsAuth.credentials,
          providerType: clientConfig.providerType,
          url: clientConfig.url,
          path: path,
          region: clientConfig.Region,
          requestmethod: 'PUT',
          requestbody: putBody,
          parms: {},
          additionalParms: {
            headers: {
              'x-session-id': clientConfig.uuid
            },
          },
        };
        return fromPromise(client.callApiGateway(apiConfig));
      });
      let loggedIncustomer = getLoggedInCustomer(state$.value);
      const refetchCustomerPayload = {
        accountNumber: loggedIncustomer.accountNumber,
        postalCode: loggedIncustomer.postalCode,
      };
      let reloadCust = of(weakActionCreators.refetchCustomer(refetchCustomerPayload));
      const source$ = forkJoin(promises, getSeriesUpdateSucceeded).pipe(
        map(responses => {
          let resumeCount = payload.length;
          if (resumeCount === responses.length) {
            gaActionCreators.createGAEvent({
              category: gaEventCategory,
              action: 'Successful',
              label: payload[0].googleAnalyticsLabel || payload[0].prodIdAlias
            });
            return actionCreators.seriesUpdateSuccess({payload, responses, success: true});
          } else {
            gaActionCreators.createGAEvent({
              category: gaEventCategory,
              action: 'Unsuccessful',
              label: payload[0].googleAnalyticsLabel || payload[0].prodIdAlias
            });
            return actionCreators.seriesUpdateError({
              success: false,
              message: 'Error on ' + gaEventCategory,
            });
          }
        }),
        catchError(err => {
          gaActionCreators.createGAEvent({
            category: gaEventCategory,
            action: 'Unknown Error',
            label: payload[0].googleAnalyticsLabel || payload[0].prodIdAlias
          });
          return of(
            actionCreators.seriesUpdateError({ success: false, message: err }),
          );
        }),
      );
      return concat(loading, source$, reloadCust);
    }),
  );
export const epics = combineEpics(updateSeries);
