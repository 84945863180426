export const SUBSCRIPTION_EXTENSION_START = 'SUBSCRIPTION_EXTENSION_START';
export const SUBSCRIPTION_EXTENSION_SUCCESS = 'SUBSCRIPTION_EXTENSION_SUCCESS';
export const SUBSCRIPTION_EXTENSION_ERROR = 'SUBSCRIPTION_EXTENSION_ERROR';
export const SUBSCRIPTION_EXTENSION_CANCEL = 'SUBSCRIPTION_EXTENSION_CANCEL';
export const SUBSCRIPTION_EXTENSION_LOADING = 'SUBSCRIPTION_EXTENSION_LOADING';
export const REPLACEMENT_ISSUE_START = 'REPLACEMENT_ISSUE_START';
export const REPLACEMENT_ISSUE_SUCCESS = 'REPLACEMENT_ISSUE_SUCCESS';
export const REPLACEMENT_ISSUE_ERROR = 'REPLACEMENT_ISSUE_ERROR';
export const REPLACEMENT_ISSUE_LOADING = 'REPLACEMENT_ISSUE_LOADING';
export const MISSED_DAMAGED_CANCEL = 'MISSED_DAMAGED_CANCEL';
export const GET_ENTITLED_ISSUES_START = 'GET_ENTITLED_ISSUES_START';
export const GET_ENTITLED_ISSUES_SUCCESS = 'GET_ENTITLED_ISSUES_SUCCESS';
export const GET_ENTITLED_ISSUES_ERROR = 'GET_ENTITLED_ISSUES_ERROR';
export const GET_ENTITLED_ISSUES_LOADING = 'GET_ENTITLED_ISSUES_LOADING';

export type Actions = {
  SUBSCRIPTION_EXTENSION_START: { type: typeof SUBSCRIPTION_EXTENSION_START; payload: boolean };
  SUBSCRIPTION_EXTENSION_LOADING: { type: typeof SUBSCRIPTION_EXTENSION_LOADING; payload: boolean };
  SUBSCRIPTION_EXTENSION_SUCCESS: { type: typeof SUBSCRIPTION_EXTENSION_SUCCESS; payload: any };
  SUBSCRIPTION_EXTENSION_ERROR: { type: typeof SUBSCRIPTION_EXTENSION_ERROR; payload: any };
  SUBSCRIPTION_EXTENSION_CANCEL: { type: typeof SUBSCRIPTION_EXTENSION_CANCEL; payload: any };
  REPLACEMENT_ISSUE_START: { type: typeof REPLACEMENT_ISSUE_START; payload: boolean };
  REPLACEMENT_ISSUE_LOADING: { type: typeof REPLACEMENT_ISSUE_LOADING; payload: boolean };
  REPLACEMENT_ISSUE_SUCCESS: { type: typeof REPLACEMENT_ISSUE_SUCCESS; payload: any };
  REPLACEMENT_ISSUE_ERROR: { type: typeof REPLACEMENT_ISSUE_ERROR; payload: any };
  MISSED_DAMAGED_CANCEL: { type: typeof MISSED_DAMAGED_CANCEL; payload: any };
  GET_ENTITLED_ISSUES_START: { type: typeof GET_ENTITLED_ISSUES_START; payload: {magCode: string, accountNumber: string, lastIssue: string} };
  GET_ENTITLED_ISSUES_LOADING: { type: typeof GET_ENTITLED_ISSUES_LOADING; payload: boolean };
  GET_ENTITLED_ISSUES_SUCCESS: { type: typeof GET_ENTITLED_ISSUES_SUCCESS; payload: any };
  GET_ENTITLED_ISSUES_ERROR: { type: typeof GET_ENTITLED_ISSUES_ERROR; payload: any };
};
// Action Creators
export const actionCreators = {
  subscriptionExtensionStart: (payload: any): Actions[typeof SUBSCRIPTION_EXTENSION_START] => ({
    type: SUBSCRIPTION_EXTENSION_START,
    payload,
  }),
  subscriptionExtensionError: (payload: any): Actions[typeof SUBSCRIPTION_EXTENSION_ERROR] => ({
    type: SUBSCRIPTION_EXTENSION_ERROR,
    payload,
  }),
  subscriptionExtensionSuccess: (payload: any): Actions[typeof SUBSCRIPTION_EXTENSION_SUCCESS] => {
    return ({ type: SUBSCRIPTION_EXTENSION_SUCCESS, payload: payload});
  },
  subscriptionExtensionLoading: (
    payload: boolean,
  ): Actions[typeof SUBSCRIPTION_EXTENSION_LOADING] => ({
    type: SUBSCRIPTION_EXTENSION_LOADING,
    payload,
  }),
  subscriptionExtensionCancel: (payload: boolean): Actions[typeof SUBSCRIPTION_EXTENSION_CANCEL] => ({
    type: SUBSCRIPTION_EXTENSION_CANCEL, payload,
  }),
  replacementIssueStart: (payload: any): Actions[typeof REPLACEMENT_ISSUE_START] => ({
    type: REPLACEMENT_ISSUE_START,
    payload,
  }),
  replacementIssueError: (payload: any): Actions[typeof REPLACEMENT_ISSUE_ERROR] => ({
    type: REPLACEMENT_ISSUE_ERROR,
    payload,
  }),
  replacementIssueSuccess: (payload: any): Actions[typeof REPLACEMENT_ISSUE_SUCCESS] => {
    return ({ type: REPLACEMENT_ISSUE_SUCCESS, payload: payload});
  },
  replacementIssueLoading: (
    payload: boolean,
  ): Actions[typeof REPLACEMENT_ISSUE_LOADING] => ({
    type: REPLACEMENT_ISSUE_LOADING,
    payload,
  }),

  getEntitledIssuesStart: (payload: any): Actions[typeof GET_ENTITLED_ISSUES_START] => ({
    type: GET_ENTITLED_ISSUES_START,
    payload,
  }),
  getEntitledIssuesError: (payload: any): Actions[typeof GET_ENTITLED_ISSUES_ERROR] => ({
    type: GET_ENTITLED_ISSUES_ERROR,
    payload,
  }),
  getEntitledIssuesSuccess: (payload: any): Actions[typeof GET_ENTITLED_ISSUES_SUCCESS] => ({
    type: GET_ENTITLED_ISSUES_SUCCESS,
    payload,
  }),
  getEntitledIssuesLoading: (
    payload: boolean,
  ): Actions[typeof GET_ENTITLED_ISSUES_LOADING] => ({
    type: GET_ENTITLED_ISSUES_LOADING,
    payload,
  }),
  missedDamagedCancel: (payload: any): Actions[typeof MISSED_DAMAGED_CANCEL] => ({
    type: MISSED_DAMAGED_CANCEL,
    payload,
  }),
};
