
export const ACTIONS = {
  PAY_NOW : 'Pay Now',
  RENEW : 'Renew Now',
  CANCELUNPAID : 'CancelUnpaid',
  CANCELPAID : 'CancelPaid',
  RESUME : 'Resume',
  RETURN : 'Return',
  TRACK : 'Track'
};

export const STATUS_MAP = {
  'canceled': {
    title: 'Canceled',
    className: 'text-dark',
    primaryAction: 'None'
  },
  'cancelled': {
    title: 'Canceled',
    className: 'text-dark',
    primaryAction: 'None'
  },
  'suspended': {
    title: 'Suspended',
    className: 'text-danger',
    primaryAction: 'Resume'
  },
  'expired': {
    title: 'Expired',
    className: 'text-danger',
    primaryAction: 'None'
  },
  'expired-renew': {
    title: 'Expired',
    className: 'warning-dark',
    primaryAction: ACTIONS.RENEW
  },
  'pastdue': {
      title: 'Payment Due',
      className: 'text-danger',
      primaryAction: ACTIONS.PAY_NOW
  },
  'active': {
    title: 'Active',
    className: 'text-dark',
    primaryAction: 'None'
  }, 
  'active-renew': {
    title: 'Active',
    className: 'text-dark',
    primaryAction: ACTIONS.RENEW
  }, 
  'renewing_soon': {
      title: 'Active',
      className: 'text-dark',
      primaryAction: ACTIONS.RENEW
  },
  'future': {
  title: 'Future',
  className: 'text-dark',
  primaryAction: 'None'
  },
  'gift': {
    title: 'Gift',
    className: 'text-dark',
    primaryAction: 'None'
  },
  'suspended over credit limit': {
    title: 'Suspended over credit limit',
    className: 'text-dark',
    primaryAction: 'None'
  },
  'unpaid': {
    title: 'Unpaid',
    className: 'text-danger',
    primaryAction: ACTIONS.PAY_NOW
  },
  'on hold': {
    title: 'On Hold',
    className: 'text-dark',
    primaryAction: 'None'
  },
  'awaiting payment': {
    title: 'Awaiting Payment',
    className: 'text-danger',
    primaryAction: ACTIONS.PAY_NOW
  },
  'completed': {
    title: 'Completed',
    className: 'text-dark',
    primaryAction: 'None'
  },
  'partially canceled': {
    title: 'Partially Canceled',
    className: 'text-dark',
    primaryAction: 'None'
  },
  'partially returned': {
    title: 'Partially Returned',
    className: 'text-dark',
    primaryAction: 'None'
  },
  'payment method needs updated': {
    title: 'Payment Method Needs Updated',
    className: 'text-danger',
    primaryAction: 'None'
  },
  'returned': {
    title: 'Returned',
    className: 'text-dark',
    primaryAction: 'None'
  },
  'transferred': {
    title: 'Transferred',
    className: 'text-dark',
    primaryAction: 'None'
  },
  'undeliverable': {
    title: 'Undeliverable',
    className: 'text-danger',
    primaryAction: 'None'
  },
  'enqueue': {
    title: 'Future',
    className: 'text-dark',
    primaryAction: 'None'
  },
  'issued': {
    title: 'Awaiting Activation',
    className: 'text-dark',
    primaryAction: 'None'
  },
  'revoked': {
    title: 'Revoked',
    className: 'text-danger',
    primaryAction: 'None'
  },
  'redeemed': {
    title: 'Activated',
    className: 'text-dark',
    primaryAction: 'None'
  },
  'activehistory': {
    title: 'Expired',
    className: 'text-danger',
    primaryAction: 'None'
  },
  'renewalinprogress': {
    title: 'Active',
    className: 'text-dark',
    primaryAction: 'None'
  },
};

export const SUBSCRIPTION_STATUSES = {
  EXPIRED : 'expired',
  PAST_DUE : 'pastdue',
  RENEWING_SOON : 'renewing_soon',
  ACTIVE: 'active',
  SUSPENDED: 'suspended',
  CANCELED: 'canceled',
  FUTURE_START: 'future',
  NSD: 'gift',
  SUSPENDED_OVER_CREDIT_LIMIT: 'suspended over credit limit',
  UNPAID: 'unpaid',
  ON_HOLD: 'on hold',
  AWAITING_PAYMENT: 'awaiting payment',
  COMPLETED: 'completed',
  PARTIALLY_CANCELED: 'partially canceled',
  PARTIALLY_RETURNED: 'partially returned',
  PAYMENT_METHOD_NEEDS_UPDATED: 'payment method needs updated',
  RETURNED: 'returned',
  TRANSFERRED: 'transferred',
  UNDELIVERABLE: 'undeliverable',
  NONE: 'None'
};

export const CREDIT_CARD_MAP = {
  MC : 'Master Card',
  AMEX : 'AMEX',
  VISA : 'VISA',
};

export const SYSTEM_CODES = {
  SERVPF : 'servpf',
  SERV : 'serv',
  EHUB : 'ehub',
};

export const CARE_TYPES = {
  CDSCARE : 'cdsCare',
  ECARE : 'eCare'
};

export const INTELLISEL_TRANSACTION_CODES = {
  ACCT_SUMMARY: 'account-summary',
  COFA_CONF: 'change-address-confirmation',
  COFE_CONF: 'change-email-confirmation',
  CANCEL_CONF: 'cancel-confirmation',
  COFA_RECIP_CONF: 'gift-change-address-confirmation',
  PAYMENT_CONF: 'payment-confirmation',
  UNPAID_SAVECANCEL_SERV: 'SERV-credit-cancel',
  PAID_SAVECANCEL_SERV: 'SERV-paid-cancel',
};

export const SHIP_METHOD_MAP = {
  'ups': {
    title: 'UPS',
    trackingUrl: 'http://wwwapps.ups.com/WebTracking/track?track=yes&trackNums='
  },
  'usps': {
    title: 'USPS',
    trackingUrl: 'https://tools.usps.com/go/TrackConfirmAction_input?qtc_tLabels1='
  },
  'fedex': {
    title: 'FedEx',
    trackingUrl: 'http://www.fedex.com/Tracking?action=track&tracknumbers='
  },
  'airborne': {
    title: 'Airborne',
    trackingUrl: ''
  },
  'canada post': {
    title: 'Canada Post',
    trackingUrl: ''
  },
  'ltl/over the road': {
      title: 'LTL/Over the Road',
      trackingUrl: ''
    },
  'dhl': {
    title: 'DHL',
    trackingUrl: ''
  }, 
  'unknown/not specified': {
    title: 'Unknown/Not Specified',
    trackingUrl: ''
  }
};
export const ALT_PAY_TYPES = {
  PP: 'PPAL',
  AP: 'APPY',
  AM: 'AZPY'
};
export const SERV_REASON_ARRAY: Array <any> = [
  {label: 'I have made duplicate payments', value: 'I have made duplicate payments', omsMapping: '1'},
  {label: 'I don\'t like the content', value: 'I don\'t like the content', omsMapping: '2'},
  {label: 'I saw a better offer', value: 'I saw a better offer', omsMapping: '3'},
  {label: 'I experienced poor service', value: 'I experienced poor service', omsMapping: '4'},
  {label: 'The subscriber has passed away', value: 'The subscriber has passed away', omsMapping: '5'},
  {label: 'Other', value: 'Other', omsMapping: '6'}
];
export const SERVPF_REASON_ARRAY: Array <any> = [
  {label: 'I not longer want this product', value: 'I not longer want this product'},
  {label: 'I did not order this product', value: 'I did not order this product'},
  {label: 'I did not receive this product', value: 'I did not receive this product'},
  {label: 'I am canceling for some other reason', value: 'I am canceling for some other reason'},
];
export const CORE_REASON_ARRAY: Array <any> =  [
  {label: 'Delivery Problem', value: 'Delivery Problem', omsMapping: 'Delivery Problem'},
  {label: 'Does Not Use/Does Not Like', value: 'Does Not Use/Does Not Like', omsMapping: 'Does Not Use/Does Not Like'},
  {label: 'Duplicate Service', value: 'Duplicate Service', omsMapping: 'Duplicate Service'},
  {label: 'Editorial Content', value: 'Editorial Content', omsMapping: 'Editorial Content'},
  {label: 'Poor Service', value: 'Poor Service', omsMapping: 'Poor Service'},
  {label: 'Too Expensive', value: 'Too Expensive', omsMapping: 'Too Expensive'},
  {label: 'Deceased', value: 'Deceased', omsMapping: 'Deceased'},
  {label: 'Miscellaneous', value: 'Miscellaneous', omsMapping: 'Miscellaneous'},
];
