const usStates = [
    { label: 'Alabama', value: 'AL'},
    { label: 'Alaska', value: 'AK'},
    { label: 'American Samoa', value: 'AS'},
    { label: 'Arizona', value: 'AZ'},
    { label: 'Arkansas', value: 'AR'},
    { label: 'California', value: 'CA'},
    { label: 'Colorado', value: 'CO'},
    { label: 'Connecticut', value: 'CT'},
    { label: 'Delaware', value: 'DE'},
    { label: 'District of Columbia', value: 'DC'},
    { label: 'Federated States of Micronesia', value: 'FM'},
    { label: 'Florida', value: 'FL'},
    { label: 'Georgia', value: 'GA'},
    { label: 'Guam', value: 'GU'},
    { label: 'Hawaii', value: 'HI'},
    { label: 'Idaho', value: 'ID'},
    { label: 'Illinois', value: 'IL'},
    { label: 'Indiana', value: 'IN'},
    { label: 'Iowa', value: 'IA'},
    { label: 'Kansas', value: 'KS'},
    { label: 'Kentucky', value: 'KY'},
    { label: 'Louisiana', value: 'LA'},
    { label: 'Maine', value: 'ME'},
    { label: 'Marshall Islands', value: 'MH'},
    { label: 'Maryland', value: 'MD'},
    { label: 'Massachusetts', value: 'MA'},
    { label: 'Michigan', value: 'MI'},
    { label: 'Minnesota', value: 'MN'},
    { label: 'Mississippi', value: 'MS'},
    { label: 'Missouri', value: 'MO'},
    { label: 'Montana', value: 'MT'},
    { label: 'Nebraska', value: 'NE'},
    { label: 'Nevada', value: 'NV'},
    { label: 'New Hampshire', value: 'NH'},
    { label: 'New Jersey', value: 'NJ'},
    { label: 'New Mexico', value: 'NM'},
    { label: 'New York', value: 'NY'},
    { label: 'North Carolina', value: 'NC'},
    { label: 'North Dakota', value: 'ND'},
    { label: 'Northern Mariana Islands', value: 'MP'},
    { label: 'Ohio', value: 'OH'},
    { label: 'Oklahoma', value: 'OK'},
    { label: 'Oregon', value: 'OR'},
    { label: 'Palau', value: 'PW'},
    { label: 'Pennsylvania', value: 'PA'},
    { label: 'Puerto Rico', value: 'PR'},
    { label: 'Rhode Island', value: 'RI'},
    { label: 'South Carolina', value: 'SC'},
    { label: 'South Dakota', value: 'SD'},
    { label: 'Tennessee', value: 'TN'},
    { label: 'Texas', value: 'TX'},
    { label: 'Utah', value: 'UT'},
    { label: 'Vermont', value: 'VT'},
    { label: 'Virgin Islands', value: 'VI'},
    { label: 'Virginia', value: 'VA'},
    { label: 'Washington', value: 'WA'},
    { label: 'West Virginia', value: 'WV'},
    { label: 'Wisconsin', value: 'WI'},
    { label: 'Wyoming', value: 'WY' }
];

const canadianProvinces = [
    { label: 'Alberta', value: 'AB'},
    { label: 'British Columbia', value: 'BC'},
    { label: 'Manitoba', value: 'MB'},
    { label: 'New Brunswick', value: 'NB'},
    { label: 'Newfoundland and Labrador', value: 'NL'},
    { label: 'Northwest Territories', value: 'NT'},
    { label: 'Nova Scotia', value: 'NS'},
    { label: 'Nunavut', value: 'NU'},
    { label: 'Ontario', value: 'ON'},
    { label: 'Prince Edward Island', value: 'PE'},
    { label: 'Quebec', value: 'QC'},
    { label: 'Saskatchewan', value: 'SK'},
    { label: 'Yukon', value: 'YT'}
];

const australianStates = [
    { label: 'Australian Capital Territory', value: 'ACT'},
    { label: 'New South Wales', value: 'NSW'},
    { label: 'Northern Territory', value: 'NT'},
    { label: 'Queensland', value: 'QLD'},
    { label: 'South Australia', value: 'SA'},
    { label: 'Tasmania', value: 'TAS'},
    { label: 'Victoria', value: 'VIC'},
    { label: 'Western Australia', value: 'WA'}
];

const brazilianStates = [
    { label: 'Acre', value: 'AC'},
    { label: 'Alagoas', value: 'AL'},
    { label: 'Amapá', value: 'AP'},
    { label: 'Amazonas', value: 'AM'},
    { label: 'Bahia', value: 'BA'},
    { label: 'Ceará', value: 'CE'},
    { label: 'Distrito Federal', value: 'DF'},
    { label: 'Espírito Santo', value: 'ES'},
    { label: 'Goiás', value: 'GO'},
    { label: 'Maranhão', value: 'MA'},
    { label: 'Mato Grosso', value: 'MT'},
    { label: 'Mato Grosso do Sul', value: 'MS'},
    { label: 'Minas Gerais', value: 'MG'},
    { label: 'Pará', value: 'PA'},
    { label: 'Paraíba', value: 'PB'},
    { label: 'Paraná', value: 'PR'},
    { label: 'Pernambuco', value: 'PE'},
    { label: 'Piauí', value: 'PI'},
    { label: 'Rio de Janeiro', value: 'RJ'},
    { label: 'Rio Grande do Norte', value: 'RN'},
    { label: 'Rio Grande do Sul', value: 'RS'},
    { label: 'Rondônia', value: 'RO'},
    { label: 'Roraima', value: 'RR'},
    { label: 'Santa Catarina', value: 'SC'},
    { label: 'São Paulo', value: 'SP'},
    { label: 'Sergipe', value: 'SE'},
    { label: 'Tocantins', value: 'TO'}
];

const italianProvinces = [
    { label: 'Agrigento', value: 'AG'},
    { label: 'Alessandria', value: 'AL'},
    { label: 'Ancona', value: 'AN'},
    { label: 'Aosta', value: 'AO'},
    { label: 'Arezzo', value: 'AR'},
    { label: 'Ascoli Piceno', value: 'AP'},
    { label: 'Asti', value: 'AT'},
    { label: 'Avellino', value: 'AV'},
    { label: 'Bari', value: 'BA'},
    { label: 'Barletta-Andria-Trani', value: 'BT'},
    { label: 'Belluno', value: 'BL'},
    { label: 'Benevento', value: 'BN'},
    { label: 'Bergamo', value: 'BG'},
    { label: 'Biella', value: 'BI'},
    { label: 'Bologna', value: 'BO'},
    { label: 'Brescia', value: 'BS'},
    { label: 'Brindisi', value: 'BR'},
    { label: 'Cagliari', value: 'CA'},
    { label: 'Caltanissetta', value: 'CL'},
    { label: 'Campobasso', value: 'CB'},
    { label: 'Caserta', value: 'CE'},
    { label: 'Catania', value: 'CT'},
    { label: 'Catanzaro', value: 'CZ'},
    { label: 'Chieti', value: 'CH'},
    { label: 'Como', value: 'CO'},
    { label: 'Cosenza', value: 'CS'},
    { label: 'Cremona', value: 'CR'},
    { label: 'Crotone', value: 'KR'},
    { label: 'Cuneo', value: 'CN'},
    { label: 'Enna', value: 'EN'},
    { label: 'Fermo', value: 'FM'},
    { label: 'Ferrara', value: 'FE'},
    { label: 'Florence', value: 'FI'},
    { label: 'Foggia', value: 'FG'},
    { label: 'Forlì-Cesena', value: 'FC'},
    { label: 'Frosinone', value: 'FR'},
    { label: 'Genoa', value: 'GE'},
    { label: 'Gorizia', value: 'GO'},
    { label: 'Grosseto', value: 'GR'},
    { label: 'Imperia', value: 'IM'},
    { label: 'Isernia', value: 'IS'},
    { label: 'L\'Aquila', value: 'AQ'},
    { label: 'La Spezia', value: 'SP'},
    { label: 'Latina', value: 'LT'},
    { label: 'Lecce', value: 'LE'},
    { label: 'Lecco', value: 'LC'},
    { label: 'Livorno', value: 'LI'},
    { label: 'Lodi', value: 'LO'},
    { label: 'Lucca', value: 'LU'},
    { label: 'Macerata', value: 'MC'},
    { label: 'Mantua', value: 'MN'},
    { label: 'Massa-Carrara', value: 'MS'},
    { label: 'Matera', value: 'MT'},
    { label: 'Messina', value: 'ME'},
    { label: 'Milan', value: 'MI'},
    { label: 'Modena', value: 'MO'},
    { label: 'Monza and Brianza', value: 'MB'},
    { label: 'Naples', value: 'NA'},
    { label: 'Novara', value: 'NO'},
    { label: 'Nuoro', value: 'NU'},
    { label: 'Oristano', value: 'OR'},
    { label: 'Padua', value: 'PD'},
    { label: 'Palermo', value: 'PA'},
    { label: 'Parma', value: 'PR'},
    { label: 'Pavia', value: 'PV'},
    { label: 'Perugia', value: 'PG'},
    { label: 'Pesaro and Urbino', value: 'PU'},
    { label: 'Pescara', value: 'PE'},
    { label: 'Piacenza', value: 'PC'},
    { label: 'Pisa', value: 'PI'},
    { label: 'Pistoia', value: 'PT'},
    { label: 'Pordenone', value: 'PN'},
    { label: 'Potenza', value: 'PZ'},
    { label: 'Prato', value: 'PO'},
    { label: 'Ragusa', value: 'RG'},
    { label: 'Ravenna', value: 'RA'},
    { label: 'Reggio Calabria', value: 'RC'},
    { label: 'Reggio Emilia', value: 'RE'},
    { label: 'Rieti', value: 'RI'},
    { label: 'Rimini', value: 'RN'},
    { label: 'Rome', value: 'RM'},
    { label: 'Rovigo', value: 'RO'},
    { label: 'Salerno', value: 'SA'},
    { label: 'Sassari', value: 'SS'},
    { label: 'Savona', value: 'SV'},
    { label: 'Siena', value: 'SI'},
    { label: 'Sondrio', value: 'SO'},
    { label: 'South Sardinia', value: 'SU'},
    { label: 'South Tyrol', value: 'BZ'},
    { label: 'Syracuse', value: 'SR'},
    { label: 'Taranto', value: 'TA'},
    { label: 'Teramo', value: 'TE'},
    { label: 'Terni', value: 'TR'},
    { label: 'Trapani', value: 'TP'},
    { label: 'Trento', value: 'TN'},
    { label: 'Treviso', value: 'TV'},
    { label: 'Trieste', value: 'TS'},
    { label: 'Turin', value: 'TO'},
    { label: 'Udine', value: 'UD'},
    { label: 'Varese', value: 'VA'},
    { label: 'Verbano-Cusio-Ossola', value: 'VB'},
    { label: 'Vercelli', value: 'VC'},
    { label: 'Venice', value: 'VE'},
    { label: 'Verona', value: 'VR'},
    { label: 'Vibo Valentia', value: 'VV'},
    { label: 'Vicenza', value: 'VI'},
    { label: 'Viterbo', value: 'VT'}
];

const mexicanStates = [
    { label: 'Aguascalientes', value: 'AGU'},
    { label: 'Baja California', value: 'BCN'},
    { label: 'Baja California Sur', value: 'BCS'},
    { label: 'Campeche', value: 'CAM'},
    { label: 'Chiapas', value: 'CHP'},
    { label: 'Chihuahua', value: 'CHH'},
    { label: 'Coahuila', value: 'COA'},
    { label: 'Colima', value: 'COL'},
    { label: 'Durango', value: 'DUR'},
    { label: 'Guanajuato', value: 'GUA'},
    { label: 'Guerrero', value: 'GRO'},
    { label: 'Hidalgo', value: 'HID'},
    { label: 'Jalisco', value: 'JAL'},
    { label: 'México', value: 'MEX'},
    { label: 'Mexico City', value: 'CMX'},
    { label: 'Michoacán', value: 'MIC'},
    { label: 'Morelos', value: 'MOR'},
    { label: 'Nayarit', value: 'NAY'},
    { label: 'Nuevo León', value: 'NLE'},
    { label: 'Oaxaca', value: 'OAX'},
    { label: 'Puebla', value: 'PUE'},
    { label: 'Querétaro', value: 'QUE'},
    { label: 'Quintana Roo', value: 'ROO'},
    { label: 'San Luis Potosí', value: 'SLP'},
    { label: 'Sinaloa', value: 'SIN'},
    { label: 'Sonora', value: 'SON'},
    { label: 'Tabasco', value: 'TAB'},
    { label: 'Tamaulipas', value: 'TAM'},
    { label: 'Tlaxcala', value: 'TLA'},
    { label: 'Veracruz', value: 'VER'},
    { label: 'Yucatán', value: 'YUC'},
    { label: 'Zacatecas', value: 'ZAC'}
];

const venezuelanStates = [
    { label: 'Amazonas', value: 'Z'},
    { label: 'Anzoátegui', value: 'B'},
    { label: 'Apure', value: 'C'},
    { label: 'Aragua', value: 'D'},
    { label: 'Barinas', value: 'E'},
    { label: 'Bolívar', value: 'F'},
    { label: 'Carabobo', value: 'G'},
    { label: 'Cojedes', value: 'H'},
    { label: 'Delta Amacuro', value: 'Y'},
    { label: 'Dependencias Federales', value: 'W'},
    { label: 'Distrito Capital', value: 'A'},
    { label: 'Falcón', value: 'I'},
    { label: 'Guárico', value: 'J'},
    { label: 'La Guaira', value: 'X'},
    { label: 'Lara', value: 'K'},
    { label: 'Mérida', value: 'L'},
    { label: 'Miranda', value: 'M'},
    { label: 'Monagas', value: 'N'},
    { label: 'Nueva Esparta', value: 'O'},
    { label: 'Portuguesa', value: 'P'},
    { label: 'Sucre', value: 'R'},
    { label: 'Táchira', value: 'S'},
    { label: 'Trujillo', value: 'T'},
    { label: 'Yaracuy', value: 'U'},
    { label: 'Zulia', value: 'V'}
];

const countries = [ 
    {label: 'United States', value: 'US'}, 
    {label: 'Canada', value: 'CA'}, 
    {label: 'Afghanistan', value: 'AF'}, 
    {label: 'Åland Islands', value: 'AX'}, 
    {label: 'Albania', value: 'AL'}, 
    {label: 'Algeria', value: 'DZ'}, 
    {label: 'American Samoa', value: 'AS'}, 
    {label: 'Andorra', value: 'AD'}, 
    {label: 'Angola', value: 'AO'}, 
    {label: 'Anguilla', value: 'AI'}, 
    {label: 'Antarctica', value: 'AQ'}, 
    {label: 'Antigua and Barbuda', value: 'AG'}, 
    {label: 'Argentina', value: 'AR'}, 
    {label: 'Armenia', value: 'AM'}, 
    {label: 'Aruba', value: 'AW'}, 
    {label: 'Australia', value: 'AU'}, 
    {label: 'Austria', value: 'AT'}, 
    {label: 'Azerbaijan', value: 'AZ'}, 
    {label: 'Bahamas', value: 'BS'}, 
    {label: 'Bahrain', value: 'BH'}, 
    {label: 'Bangladesh', value: 'BD'}, 
    {label: 'Barbados', value: 'BB'}, 
    {label: 'Belarus', value: 'BY'}, 
    {label: 'Belgium', value: 'BE'}, 
    {label: 'Belize', value: 'BZ'}, 
    {label: 'Benin', value: 'BJ'}, 
    {label: 'Bermuda', value: 'BM'}, 
    {label: 'Bhutan', value: 'BT'}, 
    {label: 'Bolivia', value: 'BO'}, 
    {label: 'Bosnia and Herzegovina', value: 'BA'}, 
    {label: 'Botswana', value: 'BW'}, 
    {label: 'Bouvet Island', value: 'BV'}, 
    {label: 'Brazil', value: 'BR'}, 
    {label: 'British Indian Ocean Territory', value: 'IO'}, 
    {label: 'Brunei Darussalam', value: 'BN'}, 
    {label: 'Bulgaria', value: 'BG'}, 
    {label: 'Burkina Faso', value: 'BF'}, 
    {label: 'Burundi', value: 'BI'}, 
    {label: 'Cabo Verde', value: 'CV'}, 
    {label: 'Cambodia', value: 'KH'}, 
    {label: 'Cameroon', value: 'CM'}, 
    {label: 'Cayman Islands', value: 'KY'}, 
    {label: 'Central African Republic', value: 'CF'}, 
    {label: 'Chad', value: 'TD'}, 
    {label: 'Chile', value: 'CL'}, 
    {label: 'China', value: 'CN'}, 
    {label: 'Christmas Island', value: 'CX'}, 
    {label: 'Cocos (Keeling) Islands', value: 'CC'}, 
    {label: 'Colombia', value: 'CO'}, 
    {label: 'Comoros', value: 'KM'}, 
    {label: 'Congo', value: 'CG'}, 
    {label: 'Congo, Democratic Republic of the', value: 'CD'}, 
    {label: 'Cook Islands', value: 'CK'}, 
    {label: 'Costa Rica', value: 'CR'}, 
    {label: 'Côte d\'Ivoire', value: 'CI'}, 
    {label: 'Croatia', value: 'HR'}, 
    {label: 'Cuba', value: 'CU'}, 
    {label: 'Curaçao', value: 'CW'}, 
    {label: 'Cyprus', value: 'CY'}, 
    {label: 'Czechia', value: 'CZ'}, 
    {label: 'Denmark', value: 'DK'}, 
    {label: 'Djibouti', value: 'DJ'}, 
    {label: 'Dominica', value: 'DM'}, 
    {label: 'Dominican Republic', value: 'DO'}, 
    {label: 'Ecuador', value: 'EC'}, 
    {label: 'Egypt', value: 'EG'}, 
    {label: 'El Salvador', value: 'SV'}, 
    {label: 'Equatorial Guinea', value: 'GQ'}, 
    {label: 'Eritrea', value: 'ER'}, 
    {label: 'Estonia', value: 'EE'}, 
    {label: 'Eswatini', value: 'SZ'}, 
    {label: 'Ethiopia', value: 'ET'}, 
    {label: 'Falkland Islands (Malvinas)', value: 'FK'}, 
    {label: 'Faroe Islands', value: 'FO'}, 
    {label: 'Fiji', value: 'FJ'}, 
    {label: 'Finland', value: 'FI'}, 
    {label: 'France', value: 'FR'}, 
    {label: 'French Guiana', value: 'GF'}, 
    {label: 'French Polynesia', value: 'PF'}, 
    {label: 'French Southern Territories', value: 'TF'}, 
    {label: 'Gabon', value: 'GA'}, 
    {label: 'Gambia', value: 'GM'}, 
    {label: 'Georgia', value: 'GE'}, 
    {label: 'Germany', value: 'DE'}, 
    {label: 'Ghana', value: 'GH'}, 
    {label: 'Gibraltar', value: 'GI'}, 
    {label: 'Greece', value: 'GR'}, 
    {label: 'Greenland', value: 'GL'}, 
    {label: 'Grenada', value: 'GD'}, 
    {label: 'Guadeloupe', value: 'GP'}, 
    {label: 'Guam', value: 'GU'}, 
    {label: 'Guatemala', value: 'GT'}, 
    {label: 'Guernsey', value: 'GG'}, 
    {label: 'Guinea', value: 'GN'}, 
    {label: 'Guinea-Bissau', value: 'GW'}, 
    {label: 'Guyana', value: 'GY'}, 
    {label: 'Haiti', value: 'HT'}, 
    {label: 'Heard Island and McDonald Islands', value: 'HM'}, 
    {label: 'Holy See (Vatican City State)', value: 'VA'}, 
    {label: 'Honduras', value: 'HN'}, 
    {label: 'Hong Kong', value: 'HK'}, 
    {label: 'Hungary', value: 'HU'}, 
    {label: 'Iceland', value: 'IS'}, 
    {label: 'India', value: 'IN'}, 
    {label: 'Indonesia', value: 'ID'}, 
    {label: 'Iran, Islamic Republic Of', value: 'IR'}, 
    {label: 'Iraq', value: 'IQ'}, 
    {label: 'Ireland', value: 'IE'}, 
    {label: 'Isle of Man', value: 'IM'}, 
    {label: 'Israel', value: 'IL'}, 
    {label: 'Italy', value: 'IT'}, 
    {label: 'Jamaica', value: 'JM'}, 
    {label: 'Japan', value: 'JP'}, 
    {label: 'Jersey', value: 'JE'}, 
    {label: 'Jordan', value: 'JO'}, 
    {label: 'Kazakhstan', value: 'KZ'}, 
    {label: 'Kenya', value: 'KE'}, 
    {label: 'Kiribati', value: 'KI'}, 
    {label: 'Korea, Democratic People\'s Republic of', value: 'KP'}, 
    {label: 'Korea, Republic of', value: 'KR'}, 
    {label: 'Kuwait', value: 'KW'}, 
    {label: 'Kyrgyzstan', value: 'KG'}, 
    {label: 'Lao People\'s Democratic Republic', value: 'LA'}, 
    {label: 'Latvia', value: 'LV'}, 
    {label: 'Lebanon', value: 'LB'}, 
    {label: 'Lesotho', value: 'LS'}, 
    {label: 'Liberia', value: 'LR'}, 
    {label: 'Libya', value: 'LY'}, 
    {label: 'Liechtenstein', value: 'LI'}, 
    {label: 'Lithuania', value: 'LT'}, 
    {label: 'Luxembourg', value: 'LU'}, 
    {label: 'Macao', value: 'MO'}, 
    {label: 'Madagascar', value: 'MG'}, 
    {label: 'Malawi', value: 'MW'}, 
    {label: 'Malaysia', value: 'MY'}, 
    {label: 'Maldives', value: 'MV'}, 
    {label: 'Mali', value: 'ML'}, 
    {label: 'Malta', value: 'MT'}, 
    {label: 'Marshall Islands', value: 'MH'}, 
    {label: 'Martinique', value: 'MQ'}, 
    {label: 'Mauritania', value: 'MR'}, 
    {label: 'Mauritius', value: 'MU'}, 
    {label: 'Mayotte', value: 'YT'}, 
    {label: 'Mexico', value: 'MX'}, 
    {label: 'Micronesia, Federated States of', value: 'FM'}, 
    {label: 'Moldova, Republic of', value: 'MD'}, 
    {label: 'Monaco', value: 'MC'}, 
    {label: 'Mongolia', value: 'MN'}, 
    {label: 'Montenegro', value: 'ME'}, 
    {label: 'Montserrat', value: 'MS'}, 
    {label: 'Morocco', value: 'MA'}, 
    {label: 'Mozambique', value: 'MZ'}, 
    {label: 'Myanmar', value: 'MM'}, 
    {label: 'Namibia', value: 'NA'}, 
    {label: 'Nauru', value: 'NR'}, 
    {label: 'Nepal', value: 'NP'}, 
    {label: 'Netherlands', value: 'NL'}, 
    {label: 'Netherlands Antilles', value: 'AN'}, 
    {label: 'New Caledonia', value: 'NC'}, 
    {label: 'New Zealand', value: 'NZ'}, 
    {label: 'Nicaragua', value: 'NI'}, 
    {label: 'Niger', value: 'NE'}, 
    {label: 'Nigeria', value: 'NG'}, 
    {label: 'Niue', value: 'NU'}, 
    {label: 'Norfolk Island', value: 'NF'}, 
    {label: 'North Macedonia', value: 'MK'}, 
    {label: 'Northern Mariana Islands', value: 'MP'}, 
    {label: 'Norway', value: 'NO'}, 
    {label: 'Oman', value: 'OM'}, 
    {label: 'Pakistan', value: 'PK'}, 
    {label: 'Palau', value: 'PW'}, 
    {label: 'Palestinian Territory, Occupied', value: 'PS'}, 
    {label: 'Panama', value: 'PA'}, 
    {label: 'Papua New Guinea', value: 'PG'}, 
    {label: 'Paraguay', value: 'PY'}, 
    {label: 'Peru', value: 'PE'}, 
    {label: 'Philippines', value: 'PH'}, 
    {label: 'Pitcairn', value: 'PN'}, 
    {label: 'Poland', value: 'PL'}, 
    {label: 'Portugal', value: 'PT'}, 
    {label: 'Puerto Rico', value: 'PR'}, 
    {label: 'Qatar', value: 'QA'}, 
    {label: 'Reunion', value: 'RE'}, 
    {label: 'Romania', value: 'RO'}, 
    {label: 'Russian Federation', value: 'RU'}, 
    {label: 'Rwanda', value: 'RW'}, 
    {label: 'Saint Helena', value: 'SH'}, 
    {label: 'Saint Kitts and Nevis', value: 'KN'}, 
    {label: 'Saint Lucia', value: 'LC'}, 
    {label: 'Saint Martin', value: 'MF'}, 
    {label: 'Saint Pierre and Miquelon', value: 'PM'}, 
    {label: 'Saint Vincent and the Grenadines', value: 'VC'}, 
    {label: 'Samoa', value: 'WS'}, 
    {label: 'San Marino', value: 'SM'}, 
    {label: 'Sao Tome and Principe', value: 'ST'}, 
    {label: 'Saudi Arabia', value: 'SA'}, 
    {label: 'Senegal', value: 'SN'}, 
    {label: 'Serbia', value: 'RS'}, 
    {label: 'Seychelles', value: 'SC'}, 
    {label: 'Sierra Leone', value: 'SL'}, 
    {label: 'Singapore', value: 'SG'}, 
    {label: 'Slovakia', value: 'SK'}, 
    {label: 'Slovenia', value: 'SI'}, 
    {label: 'Solomon Islands', value: 'SB'}, 
    {label: 'Somalia', value: 'SO'}, 
    {label: 'South Africa', value: 'ZA'}, 
    {label: 'South Georgia and the South Sandwich Islands', value: 'GS'}, 
    {label: 'Spain', value: 'ES'}, 
    {label: 'Sri Lanka', value: 'LK'}, 
    {label: 'Sudan', value: 'SD'}, 
    {label: 'Suriname', value: 'SR'}, 
    {label: 'Svalbard and Jan Mayen', value: 'SJ'}, 
    {label: 'Sweden', value: 'SE'}, 
    {label: 'Switzerland', value: 'CH'}, 
    {label: 'Syrian Arab Republic', value: 'SY'}, 
    {label: 'Taiwan, Province of China', value: 'TW'}, 
    {label: 'Tajikistan', value: 'TJ'}, 
    {label: 'Tanzania, United Republic of', value: 'TZ'}, 
    {label: 'Thailand', value: 'TH'}, 
    {label: 'Timor-Leste', value: 'TL'}, 
    {label: 'Togo', value: 'TG'}, 
    {label: 'Tokelau', value: 'TK'}, 
    {label: 'Tonga', value: 'TO'}, 
    {label: 'Trinidad and Tobago', value: 'TT'}, 
    {label: 'Tunisia', value: 'TN'}, 
    {label: 'Turkey', value: 'TR'}, 
    {label: 'Turkmenistan', value: 'TM'}, 
    {label: 'Turks and Caicos Islands', value: 'TC'}, 
    {label: 'Tuvalu', value: 'TV'}, 
    {label: 'Uganda', value: 'UG'}, 
    {label: 'Ukraine', value: 'UA'}, 
    {label: 'United Arab Emirates', value: 'AE'}, 
    {label: 'United Kingdom', value: 'GB'}, 
    {label: 'United States Minor Outlying Islands', value: 'UM'}, 
    {label: 'Uruguay', value: 'UY'}, 
    {label: 'Uzbekistan', value: 'UZ'}, 
    {label: 'Vanuatu', value: 'VU'}, 
    {label: 'Venezuela', value: 'VE'}, 
    {label: 'Viet Nam', value: 'VN'}, 
    {label: 'Virgin Islands, British', value: 'VG'}, 
    {label: 'Virgin Islands, U.S.', value: 'VI'}, 
    {label: 'Wallis and Futuna', value: 'WF'}, 
    {label: 'Western Sahara', value: 'EH'}, 
    {label: 'Yemen', value: 'YE'}, 
    {label: 'Zambia', value: 'ZM'}, 
    {label: 'Zimbabwe', value: 'ZW'} 
  ];

  const countriesWithNoPostalCodes = [
    {label: 'Angola', value: 'AO', postalCode: '%'}, 
    {label: 'Antigua and Barbuda', value: 'AG', postalCode: '%'}, 
    {label: 'Aruba', value: 'AW', postalCode: '%'}, 
    {label: 'Bahamas', value: 'BS', postalCode: '%'}, 
    {label: 'Belize', value: 'BZ', postalCode: '%'}, 
    {label: 'Benin', value: 'BJ', postalCode: '%'}, 
    {label: 'Bolivia', value: 'BO', postalCode: '%'}, 
    {label: 'Botswana', value: 'BW', postalCode: '%'}, 
    {label: 'Burkina Faso', value: 'BF', postalCode: '%'}, 
    {label: 'Burundi', value: 'BI', postalCode: '%'}, 
    {label: 'Cameroon', value: 'CM', postalCode: '%'}, 
    {label: 'Central African Republic', value: 'CF', postalCode: '%'}, 
    {label: 'Chad', value: 'TD', postalCode: '%'}, 
    {label: 'Congo, Democratic Republic of the', value: 'CD', postalCode: '%'}, 
    {label: 'Cook Islands', value: 'CK', postalCode: '%'}, 
    {label: 'Côte d\'Ivoire', value: 'CI', postalCode: '%'}, 
    {label: 'Curaçao', value: 'CW', postalCode: '%'}, 
    {label: 'Dominica', value: 'DM', postalCode: '%'}, 
    {label: 'Equatorial Guinea', value: 'GQ', postalCode: '%'}, 
    {label: 'Eritrea', value: 'ER', postalCode: '%'}, 
    {label: 'Fiji', value: 'FJ', postalCode: '%'}, 
    {label: 'Gambia', value: 'GM', postalCode: '%'}, 
    {label: 'Ghana', value: 'GH', postalCode: '%'}, 
    {label: 'Grenada', value: 'GD', postalCode: '%'}, 
    {label: 'Guyana', value: 'GY', postalCode: '%'}, 
    {label: 'Heard Island and McDonald Islands', value: 'HM', postalCode: '%'}, 
    {label: 'Korea, Democratic People\'s Republic of', value: 'KP', postalCode: '%'}, 
    {label: 'Libya', value: 'LY', postalCode: '%'}, 
    {label: 'Macao', value: 'MO', postalCode: '%'}, 
    {label: 'Mali', value: 'ML', postalCode: '%'}, 
    {label: 'Mauritania', value: 'MR', postalCode: '%'}, 
    {label: 'Panama', value: 'PA', postalCode: '%'}, 
    {label: 'Qatar', value: 'QA', postalCode: '%'}, 
    {label: 'Rwanda', value: 'RW', postalCode: '%'}, 
    {label: 'Saint Kitts and Nevis', value: 'KN', postalCode: '%'}, 
    {label: 'Saint Lucia', value: 'LC', postalCode: '%'}, 
    {label: 'Sao Tome and Principe', value: 'ST', postalCode: '%'}, 
    {label: 'Seychelles', value: 'SC', postalCode: '%'}, 
    {label: 'Suriname', value: 'SR', postalCode: '%'}, 
    {label: 'Syrian Arab Republic', value: 'SY', postalCode: '%'}, 
    {label: 'Tanzania, United Republic of', value: 'TZ', postalCode: '%'}, 
    {label: 'Tokelau', value: 'TK', postalCode: '%'}, 
    {label: 'Tonga', value: 'TO', postalCode: '%'}, 
    {label: 'Tuvalu', value: 'TV', postalCode: '%'}, 
    {label: 'Uganda', value: 'UG', postalCode: '%'}, 
    {label: 'United Arab Emirates', value: 'AE', postalCode: '%'}, 
    {label: 'United States Minor Outlying Islands', value: 'UM', postalCode: '%'}, 
    {label: 'Vanuatu', value: 'VU', postalCode: '%'}, 
    {label: 'Yemen', value: 'YE', postalCode: '%'}, 
    {label: 'Zambia', value: 'ZM', postalCode: '%'}, 
];

const countriesWithStatesOrProvinces = {
    AU: australianStates,
    BR: brazilianStates,
    CA: canadianProvinces,
    IT: italianProvinces,
    MX: mexicanStates,
    US: usStates,
    VE: venezuelanStates,
};

const countriesWithStates = {
    AU: australianStates,
    BR: brazilianStates,
    MX: mexicanStates,
    US: usStates,
    VE: venezuelanStates,
};

const countriesWithProvinces = {
    CA: canadianProvinces,
    IT: italianProvinces
};

export {
    usStates,
    canadianProvinces,
    australianStates,
    brazilianStates,
    italianProvinces,
    mexicanStates,
    venezuelanStates,
    countries, 
    countriesWithNoPostalCodes,
    countriesWithProvinces,
    countriesWithStates,
    countriesWithStatesOrProvinces
};