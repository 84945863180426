import { combineEpics, Epic, ActionsObservable, StateObservable, ofType } from 'redux-observable';
import { RootAction, RootState } from '@src/app/_redux';
import { ApiClient } from '@src/app/_global/utility/apiUtils';
import { ApiConfig } from '@src/app/_global/domain';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import { concat } from 'rxjs/observable/concat';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { actionCreators, PAYMENTS_START } from '@src/app/accounts/payments/actions';
import { actionCreators as gaActionCreators } from '@src/app/analytics/googleAnalytics/actions';
import { actionCreators as weakActionCreators } from '@src/app/login/weak/actions';
import { PaymentsPostbody, PaymentsPayload } from '@src/_api/payload/payments';
import { forkJoin } from 'rxjs';

const PAYMENT_PATH = '/payment';

const getAllSucceeded = (...responses) => {
  const successCount = responses.filter(item => item.success);
  return successCount.length === responses.length;
};
const formatPostBody = (paymentPayload: PaymentsPayload) => {
  // add logic for other OMS Payment body values later
  let body: PaymentsPostbody = {
    prodIdAlias: paymentPayload.prodIdAlias,
    accountNumber: paymentPayload.accountNumber,
    invoiceNumber: paymentPayload.invoiceNumber,
    amountPaid: paymentPayload.amountPaid,
    effort: paymentPayload.effort,
    paymentOnOrderType: paymentPayload.paymentOnOrderType,
    creditCardExpireMonth: paymentPayload.creditCardExpireMonth,
    creditCardExpireYear: paymentPayload.creditCardExpireYear,
    creditCardLastFour: paymentPayload.creditCardLastFour,
    creditCardType: paymentPayload.creditCardType,
    creditCardNumber: paymentPayload.creditCardNumber,  
    paymentType: paymentPayload.paymentType, 
    altPayInfo: paymentPayload.altPayInfo,
    authCode: paymentPayload.authCode,
    autoRenewOptin: paymentPayload.autoRenewOptIn,
    systemCode: paymentPayload.systemCode,
    coreTransactionType: 'payment',
    alternatePaymentType: paymentPayload.alternatePaymentType,
    billingAgreementId: paymentPayload.billingAgreementId,
    clientTransactionId: paymentPayload.clientTransactionId,
    authorizationDate: paymentPayload.authorizationDate,
    presettleIndicator: paymentPayload.presettleIndicator
  };
  if (paymentPayload.systemCode === 'SERVPF') {
    body.errorSwitch = 'O';
  }
  return body;
};
const applyPayment = (
  action$: ActionsObservable<RootAction>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    ofType(PAYMENTS_START),
    mergeMap(({ payload }) => {
      const clientConfig = state$.value.clientConfig.clientConfig;
      const loading = of(actionCreators.paymentsLoading(true));
      const client = new ApiClient();

      let filteredPayloads = payload.paymentsPayloads.filter(pmt => {
        return pmt.systemCode !== 'SERVPF'  || (pmt.systemCode === 'SERVPF' && !pmt.updatePayMethodOnly);
      });
      const promises = filteredPayloads.map((item: PaymentsPayload) => { 
        let body = formatPostBody(item);
        const apiConfig: ApiConfig = {
          credentialsObject: state$.value.auth.omsAuth.credentials,
          providerType: clientConfig.providerType,
          url: clientConfig.url,
          path: PAYMENT_PATH,
          region: clientConfig.Region,
          requestmethod: 'POST',
          requestbody: body,
          parms: {},
          additionalParms: {
            headers: {
              'x-session-id': clientConfig.uuid
            },
          },
        };
        return fromPromise(client.callApiGateway(apiConfig));
      });

      const source$ = forkJoin(promises, getAllSucceeded).pipe(
        map(allSucceeded => {
          if (allSucceeded) {
            payload.paymentsPayloads.forEach(pmtPayload => {
              gaActionCreators.createGAEvent({
                category: pmtPayload.googleAnalyticsCategory,
                action: 'Successful',
                value: parseFloat(pmtPayload.amountPaid) * 100,
                label: pmtPayload.googleAnalyticsLabel || pmtPayload.prodIdAlias,
              });
            });
            return actionCreators.paymentsSuccess({accountResponse: payload, success: allSucceeded});
          } else {
            payload.paymentsPayloads.forEach(pmtPayload => {
              gaActionCreators.createGAEvent({
                category: pmtPayload.googleAnalyticsCategory,
                action: 'Unsuccessful',
                value:  parseFloat(pmtPayload.amountPaid) * 100,
                label: pmtPayload.googleAnalyticsLabel || pmtPayload.prodIdAlias,
              });
            });
            return actionCreators.paymentsError({
              success: false,
              message: 'Error on Payment',
            });
          }
        }),
        catchError(err => {
          gaActionCreators.createGAEvent({
            category: payload.paymentsPayloads[0].googleAnalyticsCategory,
            action: 'Unknown Error',
            label: payload.paymentsPayloads[0].googleAnalyticsLabel || payload.paymentsPayloads[0].prodIdAlias
          });
          return of(
            actionCreators.paymentsError({ success: false, message: err }),
          );
        }),
      );
      let reloadCust: any = null;
      const refetchCustomerPayload = {
        accountNumber: payload.paymentsPayloads[0].accountNumber,
        postalCode: payload.paymentsPayloads[0].postalCode,
      };
      reloadCust = of(weakActionCreators.refetchCustomer(refetchCustomerPayload));
      return concat(loading, source$, reloadCust);
    }),
  );

export const epics = combineEpics(applyPayment);
